<template>
  <div v-if="validationField.$dirty">
    <span style="color:#FF6F59;" :class="errMsgStyle">{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    validationField: {
      type: Object,
      default: () => {}
    },
    params: {
      type: Array,
      default: () => []
    },
      errorMsg: {},
    errMsgStyle:{
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      message: ""
    };
  },
  watch: {
    validationField: {
      handler: function(v) {
        this.message = "";
        let newParams =
          this.params.length == 0 ? Object.keys(v.$params) : this.params;
        newParams.some(param => {
          let key = param;
          if (typeof param == "object") {
            key = Object.keys(param)[0];
          }
          if (!this.validationField[key]) {
            this.message = this.errorMsg ? this.errorMsg : this.$t(`validation.${key}`, param[key]);
            return true;
          }
        });
      },
      deep: true
    }
  }
};
</script>
