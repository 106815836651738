<template>
    <div>
        <div class="talentCard" v-for="i in count" :key="i">
            <div class="tw-bg-white tw-shadow-lg tw-rounded-lg tw-p-6 tw-animate-pulse tw-w-full">
                <!-- Skeleton loading for card header -->
                <div class="tw-h-[75px] tw-w-[75px] tw-bg-gray-300 tw-rounded-full tw-mb-4"></div>

                <!-- Skeleton loading for card content -->
                <div class="tw-h-4 tw-bg-gray-300 tw-rounded tw-mb-4"></div>
                <div class="tw-h-4 tw-bg-gray-300 tw-rounded tw-mb-4"></div>

                <div class="tw-h-12 tw-bg-gray-300 tw-rounded tw-mb-4"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SkeletonLoaderTalentCard",
    props: {
        count: {
            type: Number,
            default: 2
        }
    }
}
</script>

<style lang="scss" scoped>
.talentCard {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}
</style>